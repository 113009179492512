.page-body {
  padding: 1.5rem 2rem 10rem 2rem;
  width: 100%;
  display: block;
}

.page-shell {
  max-width: 100%;
}

.page-sidebar {
  position: fixed;
  padding: 7.75rem 2rem 2.5rem;
  background-color: white;
  right: 0;
  border-left: 1px solid $gray-200;
  top: 0;
  width: 400px;
  height: 100%;
  z-index: 999;
  overflow: auto;

  &.no-submenu {
    padding-top: 4.75rem;
  }
}

.page-view-container {
  &.sidebar-open {
    max-width: calc(100% - 400px);

    &.sidebar-md {
      max-width: calc(100% - 600px);
      .page-sidebar {
        width: 600px;
      }
    }

    &.sidebar-lg {
      max-width: calc(100% - 800px);
      .page-sidebar {
        width: 800px;
      }
    }
  }
}

.page-top {
  position: relative;
  background-color: $gray-100;
  padding: 1rem 2rem;

  &--simple {
    background-color: transparent;
    box-shadow: none;
  }

  .page-back {
    text-decoration: none;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    letter-spacing: 0.7px;
    margin-bottom: 1rem;

    svg {
      width: 0.75rem;
      height: 0.75rem;
      transform: translateY(-1px);
      margin-right: 0.5rem;
      path {
        fill: $blue;
      }
    }

    &:hover svg path {
      fill: $link-hover-color;
    }
  }
}

.page-header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  padding-bottom: 0.25rem;
  margin: 0.5rem 0 0;
  min-height: 44px;
}

.page-filters {
  flex: 1 1 auto;
  margin-top: 0.5rem;
}

.page-title {
  flex: 1 1 auto;
  h1 {
    margin-bottom: 0rem;
    display: flex;
    color: $gray-800;
    font-weight: bold;
    font-size: 1.75rem;
    align-items: center;
  }
  p {
    margin-bottom: 0;
    margin-top: 0.5rem;
    font-size: 1rem;
    max-width: 700px;
  }
}

.page-toolbar {
  display: flex;
  align-items: flex-end;
}

.page-tabs {
  flex-grow: 1;
}

.page-actions {
  display: flex;
  align-items: center;
}

.content-wrapper {
  padding: 32px 48px;
  background: white;
  border-radius: 4px;
  box-shadow: 2px 2px 15px transparentize(#37536b, 0.9);
}

.aside-page-sidebar {
  position: fixed;
  padding: 5rem 2.5rem 2.5rem;
  background-color: $gray-100;
  right: 0;
  top: 0;
  width: calc(40% - 2rem);
  height: 100%;
  border-left: 1px solid $white;
}

.aside-page-main {
  width: calc(60% + 2rem);
}

.page-settings {
  background-color: $cloud;
  display: flex;
}

.page-settings-nav {
  background-color: $white;
  box-shadow: 1px 0 0 $shadow;
  width: 360px;
  min-height: 100vh;
}

.page-settings-content {
  flex: 1;
}
