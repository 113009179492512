.toaster {
  position: fixed;
  top: 14px;
  right: 250px;
  width: 350px;
  z-index: 9999;
}

.toast-flex {
  display: flex;

  &-body {
    flex: 1 1 auto;
  }

  &-icon {
    padding: 0.6rem 0 0.6rem 1rem;
    flex: 0 0 20px;
  }

  &-close {
    padding: 0.75rem 1rem 0 0;
  }
}

// close icon
.toast-close {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.toast {
  position: relative;
  margin-bottom: 1rem;
  background-color: white;
  border: none;
  box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25),
    0 0 1px 0 rgba(9, 30, 66, 0.31);

  &_enter {
    opacity: 0;
    transform: translateY(20px);
  }

  &_enterActive {
    transition: opacity 300ms ease-in-out, transform 300ms ease-in;
    opacity: 1;
    transform: translateY(0px);
  }

  &_exit {
    opacity: 1;
    transform: translateY(0px);
  }

  &_exitActive {
    transform: translateY(-20px);
    opacity: 0;
    transition: opacity 300ms ease-in-out, transform 300ms ease-in;
  }
}

.toast-header {
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem 0.75rem 0.5rem;
  border-bottom: none;
  color: $gray-900;
}

.toast-body {
  padding: 0 0.75rem 0.75rem;
  line-height: 1.5;
}
