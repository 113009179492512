@import '@/src/styles/_custom';

.MatchingRow {
  padding: 1rem;
  margin: 0 0 0 -1rem;
  border-bottom: 1px solid $gray-300;
  cursor: pointer;
  opacity: 0.5;
  transition: background-color 0.3s ease-in-out, opacity 0.3s;

  &_Active {
    padding: 2rem 1rem;
    cursor: default;
    background-color: white;
    opacity: 1;

    .MatchingForm {
      pointer-events: all;
      opacity: 1;
    }
  }
}
.MatchingRowFlex {
  display: flex;
  align-items: flex-start;
}
.MatchInfoNumber {
  font-size: 1rem;
  height: 1.75rem;
  width: 1.75rem;
  color: $gray-900;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  margin-right: 1rem;
  border-radius: 50%;
  background-color: $gray-200;
}

.MatchInfo {
  display: flex;
  align-items: center;

  .CompleteIcon {
    margin-left: 1rem;
  }
  .ArrowIcon {
    margin: 0 1rem;
  }
}

.ColumnPreviewBadge {
  position: absolute;
  left: 1rem;
  top: 10px;
  font-size: 1rem;
}

.ColumnPreview {
  position: relative;
  margin-bottom: 1rem;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s;
  // this fixes a strange rendering bug when scaling element
  // in safari
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  &:hover {
    transform: scale(1.01);
  }

  &_Disabled {
    pointer-events: none;
  }

  table {
    margin: 0;
    border-radius: 100rem;
  }
  thead th {
    border: none;
  }
  th {
    background-color: $gray-200;
  }
  th:first-child {
    text-align: center;
  }
  td {
    background-color: white;
  }
  th,
  td {
    border-top: 1px solid #c4c7ca;
  }
  th:last-child,
  td:last-child {
    text-align: left;
  }
}

.ImportCard {
  svg {
    width: 30px;
    height: 30px;
    margin: 0 1rem 0 0;
  }
}
