@import '@/src/styles/_custom';

.TabsWrapper {
  display: flex;
  margin-left: -12px;
  margin-bottom: -2px;
}

.TabItem {
  padding: 0.75rem 0px;
  color: $gray-800;
  font-size: 0.875rem;
  font-weight: bold;
  position: relative;
  margin: 0 0.75rem;
  letter-spacing: 0.5px;
  text-decoration: none;
  cursor: pointer;
  flex-shrink: 0;
  transition: padding 0.3s ease-in-out;

  svg {
    width: 0.75rem;
    margin-left: 0.5rem;
    fill: $gray-800;
    transform: translateY(-2px);
  }

  &:hover {
    text-decoration: none;
    color: $gray-800;

    svg {
      fill: $gray-800;
    }
  }

  &:before {
    content: '';
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $primary;
    height: 2px;
  }
  &.isActive {
    color: $primary;
    &:before {
      display: block;
    }
    svg {
      fill: $primary;
    }
  }

  &.compact {
    padding: 0.5rem 0;
    box-shadow: 0 0 black;
  }
}
