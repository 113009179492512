@import '@/src/styles/_custom';

.Employee {
  margin-right: auto;
  :global(p) {
    margin: 0 0;
  }
}

.EmployeeId {
  color: $gray-600;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
}
