@import '@/src/styles/_custom';

$anim-ms: 350ms;
$anim-ms-transform-delay: 100ms;

.StepGroup {
  flex: 1 1 auto;
}

.Step {
  position: absolute;
  width: 100%;
  height: 100%;
}

.StepBody {
  position: relative;
  max-height: calc(100vh - 7rem);
  width: 100%;
  padding: 3rem 3rem 5rem;
  overflow-y: scroll;
  margin-left: 0;
  display: block;

  &_hasAside {
    width: 60%;
    padding-right: 5rem;
  }
}

.StepAside {
  position: absolute;
  width: 40%;
  right: 0;
  top: 0;
  padding: 3rem 3rem 5rem;
  display: block;
  height: calc(100vh - 7rem);
  overflow-y: scroll;
  background: rgb(152, 59, 235);
  background: linear-gradient(
    45deg,
    rgba(152, 59, 235, 1) 0%,
    rgba(70, 103, 182, 1) 100%
  );
  border-top-right-radius: 15px;
}

.StepFooter {
  position: absolute;
  padding: 1.5rem 3rem;
  bottom: 0;
  width: 100%;
  z-index: 99;
  background-color: $gray-100;
  border-top: 1px solid #d4d8dd;
  border-bottom-left-radius: $modal-content-border-radius;
  border-bottom-right-radius: $modal-content-border-radius;

  .StepFooterContent {
    display: flex;
  }
  .StepFooterLeft {
    flex: 0 1 auto;
    text-align: left;
  }
  .StepFooterRight {
    flex: 1 0 auto;
    text-align: right;
  }
}

.Step {
  &_entering {
    .StepTransition {
      height: 0;
      transform: translateX(50px);
      opacity: 0;
    }
    .StepFooter:before {
      opacity: 0;
    }
  }
  &_entered {
    .StepTransition {
      transition: opacity $anim-ms, transform $anim-ms ease-in-out;
      height: auto;
      transform: translateX(0);
      opacity: 1;
    }
    .StepFooter:before {
      opacity: 1;
    }
  }
  &_exiting {
    .StepTransition {
      transition: opacity $anim-ms,
        transform $anim-ms ease-in-out $anim-ms-transform-delay;
      opacity: 0;
      transform: translateX(-50px);
    }
  }
  &_exited {
    .StepTransition {
      opacity: 0;
      transform: translateX(0px);
    }
  }
}
