@import '@/src/styles/_custom';

.DisbursementDatePicker {
  :global(.SingleDatePicker) {
    display: block;
  }

  :global(.SingleDatePickerInput) {
    display: block;
    border: none;
  }

  :global(.DateInput) {
    width: 100%;
  }

  :global(.DateInput_input) {
    text-align: left;
    border: 2px solid #e9ecef;
    border-radius: 6px;
    font-size: 1rem;
  }
}
