.filter-pill-container {
  display: flex;
}

.filter-pill {
  border-radius: 9999px;
  font-size: 0.7rem;
  background-color: $gray-200;
  color: $gray-800;
  line-height: 1.5;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  transition: all 0.2s;
  height: 24px;
}

.filter-pill-label {
  padding: 0.25rem 0.5rem 0.25rem 1rem;

  &:hover {
    color: $blue;
  }
}

.filter-pill-close {
  height: 24px;
  width: 24px;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $blue;
    color: white;
  }
}

.table-filters {
  display: flex;
  flex-wrap: wrap;
}