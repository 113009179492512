@import '@/src/styles/_custom';

.RetryCell {
  color: $blue;
  font-weight: bold;
  font-size: 0.875rem;
  text-align: left;

  :global svg {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0;
  }

  .RetryCellBtn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    :global svg {
      margin-right: 0.25rem;
    }

    &:hover {
      color: mix(black, $blue, 10);
      :global(svg g) {
        stroke: mix(black, $blue, 10);
      }
    }
  }
}
