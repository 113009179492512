@import '@/src/styles/_custom';

.MarketingMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 6rem 0;

  p,
  h2 {
    margin: 10px 0 4px;
  }
  p,
  ul {
    color: #999;
    max-width: 75%;
    margin: 0 auto;
  }
}

.QualificationCallout {
  background-color: $gray-100;
  border: 1px solid $gray-200;
  font-weight: bold;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius;
  margin-bottom: 1rem;
}

.QualificationCalloutIcon {
  background-color: $orange;
  padding: 0.5rem;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
