.NavigationContainer {
  z-index: 1000;
  position: fixed;
  width: 100%;
  top: 0;
}

.MainNav {
  z-index: 1000;
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  padding: 0.75rem 2rem;
  height: 56px;
  transition: background-color 0.5s;
}

.MainNavPadder {
  display: block;
  height: 56px;
  width: 100%;
}

.LogoMark {
  margin-right: 1rem;
}

.RightNav {
  height: 26px;
  display: flex;
  align-items: center;
}

.NavSeparator {
  width: 1px;
  height: 15px;
  background-color: $gray-300;
  display: block;
  margin-right: 1rem;

  .Light & {
    background-color: $gray-700;
  }
}

.NavItem {
  color: white;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: transparent;
  border: none;
  letter-spacing: 0.5px;
  border-radius: 0.25rem;
  margin-right: 0.5rem;

  .Light & {
    color: $gray-900;
  }

  &.Active {
    color: white;
    background-color: rgba(255, 255, 255, 0.1);

    .Light & {
      color: $gray-900;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .ArrowIcon {
    margin-left: 0.375rem;
  }

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    .Light & {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }

  &.Active,
  &.Active:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.Org {
    font-size: 0.875rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }
}

.logomark {
  margin-right: 0.75rem;
  flex-shrink: 0;
}

.NavMenuPopupLink {
  color: $gray-900;
  font-weight: bold;
  font-size: 0.925rem;
  border: none;
  border-bottom: 1px solid $gray-200;
  background-color: white;
  letter-spacing: 0.5px;
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: left;

  &.Active {
    color: $primary;
  }

  &:focus,
  &:hover {
    outline: none;
    background-color: $gray-200;
  }
}

.OrgDropdown {
  max-height: 300px;
  overflow: auto;
}

.org-item-subtitle {
  text-transform: uppercase;
  font-size: 0.75rem;
  color: $gray-500;
  letter-spacing: 1px;
}

.org-search-container {
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  align-items: center;
}

.OrgLogoBubble {
  width: 24px;
  height: 24px;
  border-radius: 100px;
  overflow: hidden;
  margin: 0.25rem 0.5rem 0.25rem 0;
  background-color: white;
  padding: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: $box-shadow-lg;

  &-lg {
    width: 48px;
    height: 48px;
    padding: 0 4px;
    font-size: $font-size-lg;
  }

  &-xl {
    width: 128px;
    height: 128px;
    padding: 0 16px;
    font-size: $font-size-lg * 2;
    font-weight: $font-weight-bold;
    box-shadow: $box-shadow;
  }

  img {
    display: block;
    width: 100%;
  }
}

.SubNav {
  z-index: 1000;
  position: fixed;
  width: 100%;
  top: 56px;
  padding: 0rem 2rem 0;
  background-color: $gray-100;
  transition: box-shadow 0.3s ease-in-out;
  border-bottom: 2px solid $gray-200;
  &_scrolled {
    box-shadow: 0 5px 8px $gray-300;
  }
}

.SubNavPadder {
  display: block;
  height: 45px;
  width: 100%;
}
