@import '@/src/styles/_custom';

.StatDisplay {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: 1rem;
}

.Stat {
  font-size: 18px;
  margin: 0 0;
  font-weight: bold;
  color: $gray-900;
  font-family: $font-family-monospace;
}

.Icon {
  margin-right: 16px;
}

.Label {
  margin-bottom: 0px;
  font-size: 0.75rem;
  color: $gray-800;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
