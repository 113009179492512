@import '@/src/styles/_custom';

.MarketingMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 6rem 0;

  p,
  h2 {
    margin: 10px 0 4px;
  }
  p,
  ul {
    color: #999;
    max-width: 75%;
    margin: 0 auto;
  }
}
