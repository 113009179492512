@import '@/src/styles/_custom';

.ActionCell {
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &_Disabled {
    pointer-events: none !important;
    :global .btn,
    :global .dropdown {
      background-color: $gray-200;
      color: $gray-500;
      border-color: $gray-200;
    }
    :global svg path {
      fill: $gray-500;
    }
  }
}

.HighlightedRow {
  background-color: #dff0fe !important;
}

.RemittanceTable {
  :global .rt-td {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
}

.DetailsContent {
  display: flex;
}
.DetailsBody {
  flex: 0 0 calc(66.666% - 1.5rem);
}

:global(.btn).AdjustmentDropdownToggle {
  text-transform: none;
  font-weight: normal;
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem 0.2rem;
  letter-spacing: normal;
  color: $gray-500;
  border-color: $red;
}

.DetailsAside {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  height: 100%;
  display: block;
  overflow-y: scroll;
}

.AsideItem {
  margin-bottom: 2rem;
  .AsideLabel {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.7px;
    margin-bottom: 0.5rem;
  }
  .AsideStat {
    font-size: 1.25rem;
    font-weight: bold;
  }
}

.AsideLineItem {
  display: flex;
  margin-bottom: 1.25rem;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  &_Description {
    :global h5 {
      font-size: 1.25rem;
      line-height: 1.875rem;
      margin-bottom: 0;
      font-weight: normal;
    }
    :global p {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 0;
    }
  }
  &_Value {
    flex: 1 0 auto;
    text-align: right;
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-weight: bold;
  }
}

.AsideTotal {
  :global p {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.7px;
    margin-bottom: 0.5rem;
  }
  :global h5 {
    font-size: 2rem;
    font-weight: bold;
  }
}

.StatusCard {
  display: flex;
  align-items: flex-start;

  &_Actions {
  }

  &_Main {
    flex: 1 1 auto;
    margin-right: 1rem;
  }
}

.ProgressCard {
  padding: 1.25rem;
  &_Disabled {
    pointer-events: none;
  }
}

.ProgressCardTitle {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  :global(h3) {
    margin-left: 1rem;
    margin-bottom: 0;
  }
}
