.btn-primary.disabled,
.btn-primary:disabled {
  color: $gray-500;
  background-color: $gray-400;
  border-color: $gray-400;
}

.input-group-prepend .btn,
.input-group-append .btn {
  padding: 0.25rem 1rem;
}

.btn-outline-light {
  border-color: $gray-400;
  color: $gray-900;

  &:hover,
  &:focus {
    background-color: $gray-400;
    border-color: $gray-400;
  }
}

.dropdown {
  cursor: pointer;
}

.btn-white {
  @include button-variant(
    white,
    $gray-100,
    $gray-200,
    $gray-200,
    $gray-200,
    $gray-200
  );
}

.btn-outline-white {
  @include button-outline-variant(white, #222222, lighten(white, 5%), white);
}

.btn {
  svg {
    width: 1.25em;
    transform: translateY(-1px);
  }
}
