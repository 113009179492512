.detail-header {
  display: flex;
  align-items: center;
}
.detail-heading {
  font-size: 1.125rem;
  font-weight: bold;
  flex-grow: 1;
  margin: 0 0;
}

.detail-heading-hr {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.detail-section {
  margin: 1rem 0;
}

.detail-section-heading {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
  color: $gray-800;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

.detail-section-code {
  background-color: $gray-900;
  font-weight: bold;
  font-family: 'Andale Mono', monospace;
  color: $green;
  padding: 1rem;
  border-radius: $border-radius;
}

.detail-property {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 0.875rem;
  justify-content: space-between;
  border-bottom: 1px solid $gray-200;
  padding: 0.25rem 0;

  &:last-child {
    border-bottom: none;
  }
}

.detail-property-heading {
  font-weight: 400;
  color: $gray-500;
  margin-bottom: 0;
  margin-right: 1rem;
}

.detail-property-no-data {
  font-weight: 400;
  color: $gray-500;
  margin: 0 0;
  font-size: 0.75rem;
  font-style: italic;
}

.detail-property-value-container {
  word-break: break-all;
  text-align: right;
}

.detail-copyable-value {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    border-radius: 3px;
    color: $green;
  }

  &:after {
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 1px;
    color: $blue;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9999;
    background-color: transparentize($gray-100, 0.15);
    border-radius: 2px;
    display: none;
  }

  svg {
    flex: 1 0 auto;
  }

  &.featured {
    border: 1px dashed $shadow;
    border-radius: 4px;
    padding: 10px 8px;
    justify-content: space-between;

    svg {
      flex: initial;
    }
  }
}
