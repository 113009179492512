@import '@/src/styles/_custom';

.DetailsBanner {
  overflow: hidden;
}

.DetailsHeader {
  height: 100px;
  border-radius: 24px 24px 0 0;
  background-color: $white;
  position: relative;
}

.DetailsLogo {
  position: absolute;
  top: 56px;
  left: 24px;
  margin: 0;
}

.DetailsBody {
  padding: 16px 24px 24px 168px;
  border-radius: 0 0 24px 24px;
  background-color: $white;
  border: 1px solid $shadow;
  border-top: none;
}

.DetailsMeta {
  display: inline-flex;
  align-items: center;
}

.BrandColor {
  height: 20px;
  width: 20px;
  border-radius: 100%;
}

.Label {
  margin-right: 8px;
  margin-bottom: 0;
}

.EditIcon {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 42px;
  height: 42px;
  border: 2px solid $hyper-green;
  border-radius: 50%;
  box-shadow: $box-shadow;
  background-color: $white;

  &:hover {
    box-shadow: $input-box-shadow;
    background-color: $hyper-green;

    svg {
      color: $white;
    }
  }
}

.DotContainer {
  position: absolute;
  overflow: hidden;
  border-radius: 24px 24px 0 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.DotSquare {
  position: absolute;
  transform: rotate(60deg);
  background-image: radial-gradient($white, 2px, transparent 0);
  background-size: 10px 10px;
  background-position: 0px 0px;
  right: -90px;
  top: 30px;
  width: 150px;
  height: 150px;
}

.DotSquareTop {
  @extend .DotSquare;
  left: -135px;
  top: -85px;
}
