@import '@/src/styles/_custom';

.ResourceCard {
  margin-bottom: 1.5rem;
  overflow: hidden;
  height: 100%;
}
.ResourceCardHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $gray-200;
}
.ResourceCardFooter {
  margin-top: 1.5rem;
}

.ResourceCardFlex {
  display: flex;
  flex-direction: column;
}

.ResourceCardHeaderAside {
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  display: flex;
  align-items: center;
}

.ResourceCardBody {
  flex: 1 0 auto;
  :global(h2) {
    margin-bottom: 0;
  }
}

.ResourceCardOverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparentize(white, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ResourceCardDeleteAdminIcon {
  width: 12px;
  height: 12px;
  transform: translateY(-2px);
  :global(path) {
    fill: white;
  }
}

.DownloadLinkContainer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .DownloadLinkText {
    color: $blue;
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-right: 0.5rem;
    font-weight: bold;
  }

  &:hover {
    $hover-color: mix(black, $blue, 10);
    .DownloadLinkText {
      color: $hover-color;
    }

    .DownloadLinkIcon :global(svg) path {
      fill: $hover-color;
    }
  }

  .DownloadLinkIcon {
    width: 1rem;
    height: 1rem;
    :global(svg) {
      width: 1rem;
      height: 1rem;
      vertical-align: top;
    }
  }
}
