@import '@/src/styles/_custom';

.SearchDropdownContainer {
  position: relative;
  display: block;
}

.SelectField,
.SearchInput {
  border: 2px solid $gray-200;
  border-radius: 4px;
  width: 100%;
  height: 3rem;
  padding: 0.5rem 0.75rem;
  transition: background-color 0.2s;
}

.SelectField {
  display: flex;
  align-items: center;
  height: 4rem;

  &:hover {
    cursor: pointer;
    background-color: $gray-100;
  }
}

.SearchInput {
  height: 4rem;
}

.Dropdown {
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  right: -0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: white;
  box-shadow: $box-shadow-lg;
  z-index: 3;
}

.ListItems {
  margin-top: 1rem;
  max-height: 300px;
  overflow-y: scroll;
  border-top: 1px solid $gray-200;
}

.ListItem {
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid $gray-200;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $gray-200;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    background-color: $gray-200;
  }

  &_Disabled {
    background-color: $gray-100;
  }

  &_Disabled:hover,
  &_Disabled:focus {
    background-color: $gray-100;
    cursor: default;
  }
}
