@import '@/src/styles/_custom';

.AuthContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.AuthSplash {
  flex: 1 1 auto;
  height: 100vh;
  background-color: white;
  position: relative;

  svg {
    width: 100px;
    position: absolute;
    top: 0rem;
    left: 1rem;
  }
}

.AuthCard {
  background-color: white;
  padding: 1.5rem;
  box-shadow: $box-shadow-sm;
  border-radius: 0.5rem;
}

.AuthContent {
  flex: 0 0 550px;
  height: 100vh;
  padding: 20vh 3rem 3rem;
  position: relative;
}

.AuthFormWrapper {
  max-width: 400px;
  display: block;
  margin: 0 auto;
}

.RegisterLinkContainer {
  max-width: 400px;
  position: absolute;
  bottom: 1rem;
  transform: translateX(-50%);
  left: 50%;
}

.TransitionWrapper {
  position: relative;
}

.TransitionItem {
  position: relative;
  width: 100%;
  transition: all 300ms ease-in-out;

  &:global(.enter) {
    position: absolute;
    opacity: 0;
    transform: translateX(100px);
  }

  &:global(.enter-active) {
    opacity: 1;
    position: relative;
    transform: translateX(0px);
  }

  &:global(.exit) {
    transform: translateX(0px);
    position: absolute;
    opacity: 1;
  }

  &:global(.exit-active) {
    opacity: 0;
    transform: translateX(-100px);
  }
}

.PageLoading {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
